import React, { Component } from 'react';

import style from './Divider.module.css';

export default class Divider extends Component {

    render = () => {
        return (
            <div className={ style.container }></div>
        );
    }

}